import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function Contact(){


    return(
        <div>
            <Container>
                <br></br>
                <Row>
                    <h2>Call or Text: 682-203-7353</h2>
                </Row>
                <Row>
                    <h2>Email: Savic@autoneedsgo.com</h2>
                </Row>
                <br></br>
                <Row>
                    <h2>Work Days and Hours</h2>
                    <Col xs={5}>
                        <h3>Monday</h3>
                        <h3>Tuesday</h3>
                        <h3>Wednesday</h3>
                        <h3>Thursday</h3>
                        <h3>Friday</h3>
                        <h3>Saturday</h3>
                        <h3>Sunday</h3>
                    </Col>
                    <Col xs={5}>
                        <h3>7:00am-7:00pm</h3>
                        <h3>7:00am-7:00pm</h3>
                        <h3>7:00am-7:00pm</h3>
                        <h3>7:00am-7:00pm</h3>
                        <h3>7:00am-7:00pm</h3>
                        <h3>7:00am-5:00pm</h3>
                        <h3>Closed</h3>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Contact;