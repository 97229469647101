import React from "react";

function Appointment(){

    return(
        <div>
            <p>Appointment</p>
        </div>
    )
}

export default Appointment;