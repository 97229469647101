import React from "react";
import {Container,Nav,NavItem,NavDropdown,MenuItem, NavbarBrand} from 'react-bootstrap';
import {Navbar} from 'reactstrap';


function Footer(){
    return(
        <div className="fixed-bottom">  
            <Navbar color="secondary" warning>
                <Container>
                    <NavbarBrand>AutoNeedsGo</NavbarBrand>
                </Container>
            </Navbar>
        </div>
    );
}

export default Footer;