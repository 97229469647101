import React, { Children } from 'react';
import './NavbarHeader.css';
import {BrowserRouter as Router,Switch,Route,Link} from 'react-router-dom';
import {Navbar,Container,Nav,NavItem,NavDropdown,MenuItem} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function NavbarHeader(){
    const path = window.location.pathname;

    
    return(
    <Navbar bg="light" expand="lg">
    <Container>
        <Navbar.Brand href="/">AutoNeedsGo</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
            <Nav.Link href="/login">Login</Nav.Link>
            <NavDropdown title="Services" id="basic-nav-dropdown">
            <NavDropdown.Item href="/appointment">Book An Appointment</NavDropdown.Item>
            <NavDropdown.Item href="/services">Services</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/contact">Contact Us</Nav.Link>
        </Nav>
        </Navbar.Collapse>
    </Container>
    </Navbar>
    );
}

function CustomLink({href, children, ...props}){
    const path = window.location.pathname;
    return(
        <a href={href} {...props}>{children}</a>
    );
}

export default NavbarHeader;