import './Login.css';
import React,{useState,State} from 'react';

function Login(){
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");


    return(
        <div>
            <p>Login</p>
        </div>
    )
}

export default Login;