import './App.css';
import React,{useRef, useState} from 'react';
import { Container } from 'react-bootstrap';
import {Route,Routes} from "react-router-dom";

import NavbarHeader from './NavbarHeader';
import Footer from "./Footer";
import Appointment from './pages/Appointment';
import Login from "./pages/Login";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Home from "./pages/Home";




function App() {
  return (
    <>
      <NavbarHeader></NavbarHeader>
      <div className="container">
        <Routes>
          <Route path = "/" element={<Home/>} />
          <Route path = "/login" element={<Login/>} />
          <Route path = "/appointment" element={<Appointment/>} />
          <Route path = "/services" element={<Services/>} />
          <Route path = "/contact" element={<Contact/>} />

        </Routes>
      </div>

      <Footer/>
    </>
  );
}



export default App;
