import React from "react";
import { Container } from 'react-bootstrap';

function Home(){
    return(
        
        <div className="Body">
          <div class="waterdrop"></div>
        <body className="App-body">
        <img src="https://i.imgur.com/dVqmOaR.jpg" class="img-fluid" alt="new"/>   
          

          <h3>Email: savic@autoneedsgo.com</h3>
          <section class="center">
          </section>
        </body>
  
      </div>
    );
}

export default Home;